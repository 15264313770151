var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_c('div',{staticClass:"text-h4"},[_vm._v("Users")]),_c('v-spacer'),_c('v-select',{staticClass:"mr-3 mb-3",attrs:{"label":"Role","items":_vm.roleList,"item-text":"description","item-value":"id","clearable":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.searchParameters.roleID),callback:function ($$v) {_vm.$set(_vm.searchParameters, "roleID", $$v)},expression:"searchParameters.roleID"}}),_c('v-text-field',{staticClass:"mr-3 mb-2",attrs:{"label":"Search","append-icon":"mdi-magnify","clearable":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchParameters.searchText),callback:function ($$v) {_vm.$set(_vm.searchParameters, "searchText", $$v)},expression:"searchParameters.searchText"}}),_c('api-button',{staticClass:"mb-2",on:{"click":_vm.editNew}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Add new")],1)],1),_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.userHeaders,"items":_vm.userList,"disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.user.name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.user.forename)+" "+_vm._s(item.user.surname))]}},{key:"item.user.role",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.roleText(item)))]}},{key:"item.user.lastLoggedIn",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("whenText")(item.user.lastLoggedIn)))]}},{key:"item.user.status",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.statusText(item)))]}},{key:"item.user.password",fn:function(ref){
var item = ref.item;
return [(_vm.canChangePassword(item))?_c('a',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePassword(item)}}},[_vm._v("Change")]):_vm._e(),(_vm.canChangePassword(item) && _vm.canSendReminder(item))?_c('span',[_vm._v(" / ")]):_vm._e(),(_vm.canSendReminder(item))?_c('a',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.sendReminderCheck(item)}}},[_vm._v(_vm._s(_vm.sendLinkText(item)))]):_vm._e()]}},{key:"item.user.orderConfirmationEmail",fn:function(ref){
var item = ref.item;
return [(item.user.orderConfirmationEmail)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e(),(!item.user.orderConfirmationEmail)?_c('v-icon',[_vm._v("mdi-close")]):_vm._e()]}}],null,true)}),_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteLoadingHandler}}),_c('user-dialogue',{ref:"userDialogue",on:{"saved":_vm.refreshSearch}}),_c('change-password-dialogue',{ref:"changePasswordDialogue",attrs:{"shouldShowExisting":false},on:{"saved":_vm.refreshSearch}}),_c('confirm-dialogue',{ref:"sendLinkDialogue",attrs:{"title":"Send Link?","text":("Are your sure you want to send a link to " + (_vm.user.user.fullname) + "?"),"yesButtonText":"Yes, Send","yesButtonIcon":"mdi-send","noButtonText":"No, Cancel"},on:{"confirm":function($event){return _vm.sendReminder()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }