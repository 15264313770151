import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IUserSupplier {
    userID: string;
    supplierID: number;
}

export class UserSupplier implements IUserSupplier {

    constructor(data?: IUserSupplier) {
        if (data) this.update(data);
    }

    update(data: IUserSupplier): void {
        mapData(data, { root: () => this });
    }

    userID: string = utils.emptyGuidValue;
    supplierID: number = 0;
}
