





















































import Vue from "vue";
import Component from "vue-class-component";
import { StateChanger } from "vue-infinite-loading";
import { Watch } from "vue-property-decorator";
import ApiButton from "@/vue/components/ApiButton.vue";
import ConfirmDialogue from "@/vue/components/dialogues/ConfirmDialogue.vue";
import ChangePasswordDialogue from "@/vue/components/dialogues/ChangePasswordDialogue.vue";
import UserDialogue from "@/vue/components/dialogues/UserDialogue.vue";
import { UserSearchParameters } from "@/model/UserSearchParameters";
import { ICountry } from "@/model/Country";
import { ILookupItem } from "@/model/LookupItem";
import { ISupplier } from "@/model/Supplier";
import { UserVM, IUserVM } from "@/model/Api/VM/UserVM";
import { UserRole, UserStatus } from "@/model/Enums";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton, 
        ConfirmDialogue, 
        ChangePasswordDialogue, 
        UserDialogue 
    }
})

export default class Users extends Vue {

    //
    // -- properties
    //

    private userHeaders = [
        { text: "Name", value: "user.name", sortable: false },
        { text: "Email", value: "user.email", sortable: false },
        { text: "Role", value: "user.role", sortable: false },
        { text: "Suppliers/Countries", value: "extraDetail", sortable: false },
        { text: "Last Sign-In", value: "user.lastLoggedIn", sortable: false },
        { text: "Status", value: "user.status", sortable: false },
        { text: "Password Reset", value: "user.password", sortable: false },
        { text: "Confirmation Email", align: "center", value: "user.orderConfirmationEmail", sortable: false }
    ]

    private infiniteId: number = + new Date();
    private searchParameters = new UserSearchParameters();
    private userList: Array<IUserVM> = [];
    private countrys: Array<ICountry> = [];
    private suppliers: Array<ISupplier> = [];
    private readonly user = new UserVM();

    //
    // -- computed properties
    //

    private get roleList(): Array<ILookupItem> { return utils.enumToLookups(UserRole); }

    //
    // -- watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshSearch, 200);

    @Watch("searchParameters.searchText")
    private onSearchTextChanged() {
        this.debouncedRefreshSearch();
    }

    @Watch("searchParameters.roleID")
    private onRoleIDChanged() {
        if (this.searchParameters.roleID == undefined) {
            this.searchParameters.roleID = 0;
        }
        this.debouncedRefreshSearch();
    }

    //
    // -- methods
    //

    private canSendReminder(user: UserVM): boolean {
        return !user.user.isDeleted && (user.user.status == UserStatus.InitialEmailFailed || user.user.status >= UserStatus.EmailConfirmed);
    }

    private canChangePassword(user: UserVM): boolean {
        return !user.user.isDeleted && user.user.status == UserStatus.Active;
    }

    private sendLinkText(user: UserVM): string {
        return user.user.status == UserStatus.InitialEmailFailed ? "Send another link" : "Send a link";
    }

    private roleText(user: UserVM): string {
        if (user.user.role === UserRole.None) return "None";
        if (user.user.role === UserRole.SuperAdmin) return "Super Admin";
        if (user.user.role === UserRole.Admin) return "Admin";
        if (user.user.role === UserRole.Supplier) return "Supplier";
        if (user.user.role === UserRole.Printer) return "Printer";
        if (user.user.role === UserRole.Retailer) return "Retailer";
        return "Unknown";
    }

    private statusText(user: UserVM): string {
        if (utils.hasDateValue(user.user.deleted)) return "Archived";
        if (user.user.status === UserStatus.InitialEmailPending) return "Email pending";
        if (user.user.status === UserStatus.InitialEmailFailed) return "Email FAILED";
        if (user.user.status === UserStatus.InitialEmailSent) return "Email sent";
        if (user.user.status === UserStatus.EmailConfirmed) return "Email confirmed";
        if (user.user.status === UserStatus.Active) return "Active";
        return "Unknown";
    }

    private editNew() {
        const dialog: UserDialogue = this.$refs.userDialogue as UserDialogue;
        dialog.add();
    }
    
    private edit(user: IUserVM) {
        const dialog: UserDialogue = this.$refs.userDialogue as UserDialogue;
        dialog.edit(user.user.id);
    }

    private refreshSearch() {
        this.userList = [];
        this.searchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    async infiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
        const response = await apiClient.post("/Api/User/Search", this.searchParameters);
        if (response.list.length) {
            // Use map to convert IUser data objects into actual instances of User class
            // (three dots is spread operator for concatenating arrays)
            this.userList.push(...response.list.map((u: IUserVM) => new UserVM(u)));
            this.searchParameters.pageNumber += 1;
            stateChanger.loaded();
        }
        else {
            stateChanger.complete();
        }  
    }
    
    private changePassword(user: IUserVM) {
        const dialogue = this.$refs.changePasswordDialogue as ChangePasswordDialogue;
        dialogue.changePassword(user.user);
    }

    private sendReminderCheck(user: IUserVM) {
        this.user.update(user);
        const dialogue = this.$refs.sendLinkDialogue as ConfirmDialogue;
        dialogue.show();
    }

    async sendReminder(): Promise<void> {
        const request = { email: this.user.user.email }
        const response = await apiClient.post("/Api/Authentication/forgot", request, "send-reminder") as { isSuccess: boolean; message: string };
        if (response.isSuccess) {
            toastr.success(this.user.user.forename, "Sent");
            (this.$refs.sendLinkDialogue as ConfirmDialogue).hide();
        }
        else {
            toastr.error(response.message); // could possibly use message box rather than toast?
        }
    }

}
