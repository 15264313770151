import { IUser, User } from "../../User";
import { mapData } from "@/utilities/DataMapping";

export interface IUserVM {
    user: IUser;
    extraDetail: string;
}

export class UserVM implements IUserVM {

    constructor(data?: IUserVM) {
        if (data) this.update(data);
    }

    update(data: IUserVM): void {
        mapData(data, { 
            root: () => this,
            user: () => new User()
        });
    }

    user: User = new User();
    extraDetail: string = "";
}
