


















































































import Vue from "vue";
import Component from "vue-class-component";
import * as toastr from "toastr";
import ApiButton from "@/vue/components/ApiButton.vue";
import ConfirmDialogue from "@/vue/components/dialogues/ConfirmDialogue.vue";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import { IFactory } from "@/model/Factory";
import { IPrinterVM, PrinterVM } from "@/model/Api/VM/PrinterVM";
import { ISaveResponse } from "@/model/ISaveResponse";
import { UserRole } from "@/model/Enums";
import { ILookupItem } from "@/model/LookupItem";
import { Ref, Watch } from "vue-property-decorator";
import { UserVM } from "@/model/Api/VM/UserVM";
import { IUserSupplier, UserSupplier } from "@/model/UserSupplier";
import { IUserPrinter, UserPrinter } from "@/model/UserPrinter";
import { VForm } from "@/vForm";

@Component({ components: { 
    ApiButton, 
    ConfirmDialogue 
} })

export default class UserDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("userForm") private readonly userForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private user: UserVM = new UserVM();
    private suppliers: Array<IUserSupplier> = [];
    private printers: Array<IUserPrinter> = [];
    private availableSuppliers: Array<ILookupItem> = [];
    private availablePrinters: Array<IPrinterVM> = [];
    private availableFactories: Array<IFactory> = [];

    //
    // -- computed properties
    //
    
    private get roleList(): Array<ILookupItem> { 
        return utils.enumToLookups(UserRole); 
    }

    private get saveButtonText() {
        return utils.hasDateValue(this.user.user.deleted) ? "Save and un-archive" : "Save";
    }

    private get isEditingSelf() {
        if (!this.user.user) return false;
        if (!this.$store.state.signedInUser) return false;
        return this.user.user.id === this.$store.state.signedInUser.id;
    }

    //
    // -- validations
    //

    private emailErrorMessages: Array<string> = [];

    @Watch("user.user.email")
    private async validateEmail() {
        this.emailErrorMessages = [];
        if (utils.isEmptyOrWhitespace(this.user.user.email))  return;

        const response: { isInUse: boolean } = await apiClient.get(`/api/user/emailIsInUse?email=${this.user.user.email}&userID=${this.user.user.id}`);
        if (response.isInUse) {
            this.emailErrorMessages.push("Email address is already in use");
            return;
        }
    }

    //
    // -- methods
    //

    private get canDelete() {
        return !this.user.user.isNew && !utils.hasDateValue(this.user.user.deleted);
    }

    add(): void {
        this.reset();
        this.loadSuppliers();
        this.loadPrinters();
        this.dialogueTitle = "Add User";
        this.showDialogue = true;
    }

    async edit(id: string): Promise<void> {
        this.reset();
        this.dialogueTitle = "Edit User";
        this.loadSuppliers();
        this.loadPrinters();
        const responseData = await apiClient.get(`api/user/Load?id=${id}`);
        this.user.update(responseData.user);
        this.suppliers.push(...responseData.suppliers)
        this.printers.push(...responseData.printers)
        this.showDialogue = true;
    }

    async loadSuppliers(): Promise<void> {
        this.availableSuppliers = [];
        const response = await apiClient.get("/api/supplier/loadAll");
        this.availableSuppliers.push(...response);
    }

    async loadPrinters(): Promise<void> {
        this.availablePrinters = [];
        const response = await apiClient.get("/api/printer/loadAll");
        this.availablePrinters.push(...response.map((p: IPrinterVM) => new PrinterVM(p)));
    }

    private reset() {
        utils.resetObject(this.user);
        this.suppliers = [];
        this.printers = [];
        this.user.user.id = utils.emptyGuidValue;
        this.userForm?.resetValidation();
        this.emailErrorMessages = [];
    }

    private async addSupplier() {
        this.suppliers.push(new UserSupplier());
    }

    private async addPrinter() {
        this.printers.push(new UserPrinter());
    }

    private async removeSupplier(supplier: UserSupplier) {
        for(var i = 0; i < this.suppliers.length; i++) {
            if(this.suppliers[i].supplierID === supplier.supplierID) {
                this.suppliers.splice(i, 1);
                break;
            }
        }
    }

    private async removePrinter(printer: UserPrinter) {
        for(var i = 0; i < this.printers.length; i++) {
            if(this.printers[i].printerID === printer.printerID) {
                this.printers.splice(i, 1);
                break;
            }
        }
    }

    private async save() {
        await this.validateEmail();
        const isValid = this.userForm.validate() && this.emailErrorMessages.length === 0;
        if (!isValid) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        const postData = {
            user: this.user.user,
            suppliers: this.suppliers,
            printers: this.printers
        }

        const response: ISaveResponse = await apiClient.post("/api/user/save", postData, "user-save");
        if (this.user.user.isNew) this.user.user.id = response.newID;
        toastr.success("Saved");
        this.$emit("saved");    
        this.showDialogue = false;
    }

    private confirmDelete() {
        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async doDelete() {
        await apiClient.post("/api/user/delete", this.user.user, "user-delete");
        toastr.warning("Deleted");
        this.$emit("saved");
        this.showDialogue = false;
    }

}
