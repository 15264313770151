import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IUserPrinter {
    userID: string;
    printerID: number;
}

export class UserPrinter implements IUserPrinter {

    constructor(data?: IUserPrinter) {
        if (data) this.update(data);
    }

    update(data: IUserPrinter): void {
        mapData(data, { root: () => this });
    }

    userID: string = utils.emptyGuidValue;
    printerID: number = 0;
}
